import axios from "axios";
import getCSUserMeta from "../utils/getCSUserMeta";
import { store } from "../reduxToolkit/store";
import { REMOVE_AUTH_STATE } from "../modules/auth/redux/authSlice";
import { RESET_NOTIFICATION_TOP_BAR } from "../common/redux/notificationTopBarSlice";

let axiosCSPrivateInstance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosCSPrivateInstance.interceptors.request.use(
  (config) => {
    const { CSToken } = getCSUserMeta();
    config.headers["Authorization"] = `Bearer ${CSToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosCSPrivateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error?.response?.status === 401 && !originalConfig._retry) {
      console.log("axiosCSPrivateInstance 401");
      store.dispatch(REMOVE_AUTH_STATE());
      store.dispatch(RESET_NOTIFICATION_TOP_BAR());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosCSPrivateInstance;
