export default {
  HOME: "/",
  SONIC_CS_FROM: "/music-bank-cs",
  SONIC_CS: "/music-bank-cs/:from",
  LOGIN: "/login",
  LOGOUT: "/logout",
  UNAUTHORIZED: "/unauthorized",
  PROJECTS: "/projects",
  SONICSPACE: "/sonic-space",
  WORKSPACE: "/work-space",
  WORKSPACE_PROJECT: "/work-space/project/:project_id",
  WORKSPACE_BY_PROJECT_ID_AND_CUE_ID_OPTIONS:
    "/work-space/:project_id?/:cue_id?",
  CS_OPTIONS: "/work-space/options",
  FLAX_PROJECT_SETTINGS: "/work-space/project-settings/:flax_id",
  PROJECT_SETTINGS: "/work-space/project-settings",
  RECENT_AI_MUSIC: "/work-space/recent-music",
  FLAX_TRACK: "/work-space/flax-track",
  VOICE: "/work-space/voice",
  UPLOAD_VIDEO: "/work-space/upload-video",
  BRAND_TAGS: "/work-space/brand-tags",
  RETAIN_AUDIO: "/work-space/upload-video/retain-audio",
  LIKE_DISLIKE_CUE_ID_REPORT: "/report/cueid",
  MONTH_WISE_AI_TRACK_GENERATED_REPORT: "/report/cueid-generated",
  MONTH_WISE_AI_TRACK_DETAILS: "/report/cueid-generated/details/:month",
  VOICE_TRACK_REPORT: "/report/voice-track",
  DICT_REPORT: "/report/dictionary",
};
